import type {useHostSettings} from '#imports';

interface PotentialAction {
    '@type': string;
    target: string;
    'query-input': string;
}

interface ContactPointItem {
    '@type': string;
    telephone?: string;
    contactType: string;
    email?: string;
    areaServed: string;
}

export interface MicrodataRecord {
    '@context': string;
    '@type': string;
    name: string;
    url: string;
    potentialAction?: PotentialAction;
    logo?: string;
    sameAs?: string[];
    contactPoint?: ContactPointItem[];
    address?: string;
}

function formatMicrodata(hostSettings: (ReturnType<typeof useHostSettings> extends Promise<infer R> ? R : never) | undefined): MicrodataRecord[] {
    if (!hostSettings) {
        return [];
    }
    const baseUrl = hostSettings.url;
    return [
        {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            name: hostSettings.brandNames,
            url: baseUrl,
            potentialAction: {
                '@type': 'SearchAction',
                target: `${baseUrl}/s?q={search_term_string}`,
                'query-input': 'required name=search_term_string',
            },
        },
        {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            name: hostSettings.brandNames,
            url: baseUrl,
            logo: hostSettings.brandLogo,
            sameAs: [hostSettings.facebook, hostSettings.linkedIn].filter((item): item is string => !!item),
            contactPoint: [
                {
                    '@type': 'ContactPoint',
                    telephone: hostSettings.phoneNumber,
                    contactType: 'customer service',
                    email: hostSettings.customerSupportEmail,
                    areaServed: hostSettings.country,
                },
            ],
        },
    ];
}

export function useMicrodata() {
    return {
        formatMicrodata,
    };
}
