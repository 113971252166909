<template>
    <main>
        <SliceZone :slices="slices" :components="components" />
    </main>
</template>

<script setup lang="ts">
    import {useHead, definePageMeta, useWebsitePageView, createError} from '#imports';
    import {useAsyncData} from '#app';
    import {SliceZone} from '@prismicio/vue';
    import {inject, ref} from 'vue';
    import {HOST_SETTINGS, PRISMIC_DOCUMENT} from '~/app/keys';
    import {usePrismicDocument} from '~/composables/content/usePrismicDocument';
    import {useABTestSlices} from '~/composables/useABTestSlices';
    import type {MicrodataRecord} from '~/composables/useMicrodata';
    import {useMicrodata} from '~/composables/useMicrodata';
    import {components} from '~/slices/components';

    const hostSetting = inject(HOST_SETTINGS);
    const microData = useMicrodata().formatMicrodata(hostSetting);

    const {fetchDocument} = usePrismicDocument('homepage');
    const document = inject(PRISMIC_DOCUMENT, ref());
    const {data, error} = await useAsyncData('homepage', () => fetchDocument());
    if (error.value) {
        throw createError(error.value);
    }
    document.value = data.value!;

    const {slices} = useABTestSlices(document.value);

    if (microData) {
        useHead({
            script: microData.map(microdataToScript),
        });
    }

    function microdataToScript(record: MicrodataRecord) {
        return {
            type: 'application/ld+json',
            children: JSON.stringify(record, null, 2),
        };
    }

    definePageMeta({
        middleware: 'redirect-to-home',
    });

    useWebsitePageView('cms', 'homepage');
</script>
